.Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4e4e1;
  background-image: radial-gradient(
      at top center,
      rgba(255, 255, 255, 0.03) 0%,
      rgba(0, 0, 0, 0.03) 100%
    ),
    linear-gradient(
      to top,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(143, 152, 157, 0.6) 100%
    );
  background-blend-mode: normal, multiply;
  width: 100%;
  height: 100vh;
}

.AuthForm {
  width: 400px;
  background: #eaecf0;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  padding: 30px 40px 30px 40px;
  align-items: center;
}

.AuthFormTitle {
  font-size: 24px;
  text-align: center;
  margin-bottom: 25px;
}

.AuthFormLabel {
  width: 100%;
  display: block;
  margin-bottom: 15px;
  font-size: 20px;
}

.InputWrapper {
  margin-bottom: 30px;
}

.AuthFormInput {
  font-size: 16px;
  padding: 5px 10px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(145, 145, 145);
  background-color: inherit;
  &:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px #eaecf0; /* цвет вашего фона */
    -webkit-text-fill-color: black; /* цвет текста */
  }
  &:focus {
    outline: none;
    border-bottom: 2px solid rgb(0, 0, 0);
  }
}

.PasswordErrorsWrapper {
  height: 40px;
}

.LoginErrorsWrapper {
  height: 20px;
}

.SubmitButton {
  width: 100%;
  padding: 10px 0;
  font-size: 18px;
  border-radius: 8px;
  margin-bottom: 25px;
  background-color: #b9b9b9;
  border: 1px solid #cdcdcd;
  cursor: pointer;
  text-shadow: 1px 0px 8px rgb(255, 255, 255);
  &:hover {
    box-shadow: 0px 0px 6px 6px rgba(92, 96, 99, 0.2);
  }
  &:active {
    transform: scale(1.05);
    transition: all 0.5s;
  }
}

.LogoWrapper > div {
  width: 100%;
  display: flex;
  justify-content: center;
  transform: scale(0.8);
}
.Error {
  color: rgb(253, 69, 69);
  display: block;
  width: 100%;
}

@media (max-width: 470px) {
  .Wrapper {
    width: 100%;
    height: 100%;
    background: none;
  }

  .AuthForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
    border-radius: 0px;
    box-shadow: none;
  }

  .AuthFormTitle {
    margin-bottom: 35px;
  }

  .AuthFormLabel {
    width: 100%;
    display: block;
    margin-bottom: 35px;
    font-size: 20px;
  }

  .InputWrapper {
    margin-bottom: 35px;
    width: 80%;
  }

  .SubmitButton {
    width: 80%;
    &:active {
      transform: scale(1.05);
      transition: all 0.5s;
    }
  }

  .LogoWrapper > div {
    width: 100%;
    display: flex;
    justify-content: center;
    transform: scale(0.8);
  }

  .Error {
    color: rgb(253, 69, 69);
  }

  .PasswordErrorsWrapper {
    height: 40px;
  }

  .LoginErrorsWrapper {
    height: 36px;
  }
}
