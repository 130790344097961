.BookingForm {
  max-width: 750px;
  max-height: 900px;
  padding: 30px 0 30px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
}

.WorkPlacesTitle {
  font-size: 18px;
  background: #555555;
  border-radius: 9px 9px 0px 0px;
  height: 40px;
  color: #e5e2e2;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.WorkPlacesWrapper {
  background-color: #ffffff;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  max-width: 350px;
}

.WorkPlacesButtonWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 30px 20px 20px 20px;
}

.WorkPlacesButton {
  width: 80px;
  height: 80px;
  background-color: #ffffff;
  font-size: 48px;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  &_Active {
    background-color: #89ca9f;
  }
}

.DateWrapper {
  background-color: #ffffff;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
  height: 390px;
  border-radius: 16px;
}

.DateLabel {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  background: #555555;
  border-radius: 9px 9px 0px 0px;
  height: 40px;
  color: #e5e2e2;
  padding-left: 20px;
}

.WorkTimeTitle {
  font-size: 18px;
  background: #555555;
  border-radius: 9px 9px 0px 0px;
  height: 40px;
  color: #e5e2e2;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ResetButton,
.UpdateButton {
  color: #e5e2e2;
  border: none;
  background: #8a8a8a;
  padding: 5px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 3px 3px rgba(211, 206, 206, 0.185);
  }
  &:active {
    background: #706f6f;
  }
}
.ResetButton {
  margin-left: 8px;
}

.WorkTimeButtonsWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 30px 0 30px;
}

.WorkTimeButton {
  width: 80px;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid gray;
  cursor: pointer;
  &_Booked {
    background-color: #acacac;
    cursor: default;
  }
  &_Select {
    background-color: #89ca9f;
  }
  margin: 10px;
}

.BookingButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.BookingButton {
  width: 240px;
  height: 50px;
  border-radius: 30px;
  background-color: #b9b9b9;
  border: 1px solid #cdcdcd;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 6px 6px rgba(92, 96, 99, 0.2);
  }
  &:active {
    box-shadow: 0px 0px 6px 6px rgba(92, 96, 99, 0.2);
    transform: scale(1.1);
    background-color: #838383;
    transition: all 0.5s;
  }
  &:disabled {
    cursor: default;
    box-shadow: none;
    background-color: #dadada;
  }
}

.DateCalendar {
  border: none;
  padding: 0 15px;
  font-size: 18px;
}

.WorkTimeWrapper {
  background-color: #ffffff;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding-bottom: 20px;
  width: 750px;
}

.WorkTimeWrapperSkeleton {
  background-color: unset;
  box-shadow: none;
  border-radius: 0;
  padding-bottom: 0;
}

.MessageContainer {
  height: 40px;
}

.Error {
  color: rgb(253, 69, 69);
  display: block;
  width: 100%;
  padding: 0 10%;
  text-align: center;
}

.Acces {
  color: rgb(80, 184, 106);
  display: block;
  width: 100%;
  padding: 0 10%;
  text-align: center;
}

@media (max-width: 970px) {
  .BookingForm {
    max-width: 750px;
    max-height: 900px;
    padding: 30px 0 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
  }

  .WorkPlacesTitle {
    font-size: 18px;
    background: #555555;
    border-radius: 9px 9px 0px 0px;
    height: 40px;
    color: #e5e2e2;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  .WorkPlacesWrapper {
    background-color: #ffffff;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    max-width: none;
    width: 100%;
  }

  .WorkPlacesButtonWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 20px;
    padding: 30px 20px 20px 20px;
  }
  .WorkPlacesButton {
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    font-size: 34px;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    &_Active {
      background-color: #89ca9f;
    }
  }

  .DateWrapper {
    background-color: #ffffff;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
    height: auto;
    border-radius: 16px;
  }

  .DateLabel {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    background: #555555;
    border-radius: 9px 9px 0px 0px;
    height: 40px;
    color: #e5e2e2;
    padding-left: 20px;
  }

  .WorkTimeTitle {
    font-size: 18px;
    background: #555555;
    border-radius: 9px 9px 0px 0px;
    height: 40px;
    color: #e5e2e2;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }
  .WorkTimeButtonsWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px 30px 0 30px;
  }

  .WorkTimeButton {
    width: 80px;
    height: 30px;
    border-radius: 4px;
    background-color: white;
    border: 1px solid gray;
    cursor: pointer;
    &_Booked {
      background-color: #acacac;
      cursor: default;
    }
    &_Select {
      background-color: #89ca9f;
    }
    margin: 10px;
  }

  .BookingButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .BookingButton {
    width: 240px;
    height: 50px;
    border-radius: 30px;
    background-color: #eaecf0;
    border: 1px solid #cdcdcd;
    font-size: 18px;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 6px 6px rgba(92, 96, 99, 0.2);
    }
    &:active {
      box-shadow: 0px 0px 6px 6px rgba(92, 96, 99, 0.2);
      transform: scale(1.1);
      background-color: #bdbdbd;
      transition: all 0.5s;
    }
    &:disabled {
      cursor: default;
      box-shadow: none;
    }
  }

  .DateCalendar {
    border: none;
    padding: 20px 30px;
    font-size: 18px;
    width: 100%;
    height: 400px;
    border-radius: 16px;
  }

  .WorkTimeWrapper {
    background-color: #ffffff;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding-bottom: 20px;
    width: auto;
    max-width: 750px;
  }

  .WorkTimeWrapperSkeleton {
    background-color: unset;
    box-shadow: none;
    border-radius: 0;
    padding-bottom: 0;
  }

  .MessageContainer {
    height: 40px;
  }

  .Error {
    padding: 0 10%;
    color: rgb(253, 69, 69);
    display: block;
    width: 100%;
    text-align: center;
  }

  .Acces {
    color: rgb(80, 184, 106);
    display: block;
    width: 100%;
    padding: 0 10%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  //сделать дизайн под мобилку
  .BookingForm {
    max-width: 750px;
    max-height: 900px;
    padding: 30px 0 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
  }

  .WorkPlacesTitle {
    font-size: 18px;
    background: #555555;
    border-radius: 9px 9px 0px 0px;
    height: 40px;
    color: #e5e2e2;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  .WorkPlacesWrapper {
    background-color: #ffffff;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    max-width: none;
    width: 100%;
  }

  .WorkPlacesButtonWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 20px;
    padding: 20px 20px 10px 20px;
  }

  .WorkPlacesButton {
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    font-size: 28px;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    border: none;
    cursor: pointer;
    &_Active {
      background-color: #89ca9f;
    }
  }

  .DateWrapper {
    background-color: #ffffff;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
  }

  .DateLabel {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    background: #555555;
    border-radius: 9px 9px 0px 0px;
    height: 40px;
    color: #e5e2e2;
    padding-left: 20px;
  }

  .WorkTimeTitle {
    font-size: 18px;
    background: #555555;
    border-radius: 9px 9px 0px 0px;
    height: 60px;
    color: #e5e2e2;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  .WorkTimeButtonsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .WorkTimeButton {
    width: 100%;
    height: 40px;
    font-size: 18px;
    border-radius: 4px;
    background-color: white;
    border: 1px solid gray;
    cursor: pointer;
    &_Booked {
      background-color: #acacac;
      cursor: default;
    }
    &_Select {
      background-color: #89ca9f;
    }
    margin: 10px 0;
  }

  .BookingButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .BookingButton {
    width: 80%;
    height: 50px;
    border-radius: 15px;
    background-color: #eaecf0;
    border: 1px solid #cdcdcd;
    font-size: 18px;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 6px 6px rgba(92, 96, 99, 0.2);
    }
    &:active {
      box-shadow: 0px 0px 6px 6px rgba(92, 96, 99, 0.2);
      transform: scale(1.1);
      background-color: #bdbdbd;
      transition: all 0.5s;
    }
    &:disabled {
      cursor: default;
      box-shadow: none;
    }
  }

  .DateCalendar {
    border: none;
    padding: 20px 30px;
    font-size: 18px;
    width: 100%;
    height: max-content;
    border-radius: 16px;
  }

  .WorkTimeWrapper {
    background-color: #ffffff;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding-bottom: 20px;
    width: 100%;
  }

  .WorkTimeWrapperSkeleton {
    background-color: unset;
    box-shadow: none;
    border-radius: 0;
    padding-bottom: 0;
  }

  .MessageContainer {
    height: 80px;
  }

  .UpdateButtonWrapper {
    width: 80%;
    display: flex;
    justify-content: flex-end;
  }

  .Error {
    color: rgb(253, 69, 69);
    display: block;
    width: 100%;
    padding: 0 10%;
    text-align: center;
  }

  .Acces {
    color: rgb(80, 184, 106);
    display: block;
    width: 100%;
    padding: 0 10%;
    text-align: center;
  }
}
