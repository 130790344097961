.Wrapper {
  transform: scale(0.9);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ImgStandart {
  width: 100%;
  height: auto;
}

.ImgQuiet {
  width: 80%;
  height: auto;
}

.DescriptionWrapper {
  margin-bottom: 16px;
  font-size: 20px;
}
.DescriptionTitle {
  font-weight: 600;
  font-size: 22px;
}

@media (max-width: 1844px) {
  .Wrapper {
    transform: scale(0.9);
    display: flex;
    justify-content: center;
    width: 750px;
  }
  .Img {
    width: 120%;
    height: auto;
  }
}

@media (max-width: 970px) {
  .Wrapper {
    transform: scale(0.8);
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .Img {
    width: 120%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .Wrapper {
    transform: scale(0.9);
  }
  .DescriptionWrapper {
    margin-bottom: 16px;
    font-size: 16px;
  }
  .DescriptionTitle {
    font-weight: 600;
    font-size: 18px;
  }
}
