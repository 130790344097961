.Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  min-width: 750px;
  position: relative;
}

.ContentWrapper {
  width: 100%;
}

.TitleWrapper {
  font-size: 24px;
  background: #555555;
  border-radius: 9px 9px 0px 0px;
  height: 40px;
  color: #e5e2e2;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.TableWrapper {
  background-color: #ffffff;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  height: 600px;
  width: 100%;
}

.Table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}

.TableHead {
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 1;
}

.Table th {
  font-size: 24px;
  font-weight: 500;
}

.Table thead tr {
  height: 50px;
}

.Table th,
.Table td {
  width: 25%;
  border-right: 1px solid #000;
  padding: 10px;
}

.Table td {
  height: 40px;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
}

.Table th:last-child,
.Table td:last-child {
  border-right: none;
}

.Button {
  color: #e5e2e2;
  border: none;
  background: #8a8a8a;
  padding: 5px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 3px 3px rgba(211, 206, 206, 0.185);
  }
  &:active {
    background: #706f6f;
  }
}

.DeleteButton {
  color: #e5e2e2;
  border: none;
  background: none;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 3px 3px rgba(211, 206, 206, 0.185);
    background-color: #ebebeb;
  }
  &:active {
    background: #b3b3b3;
  }
}

.DeleteButton img {
  filter: opacity(80%) invert(40%);
}

@media (max-width: 970px) {
  .Wrapper {
    min-width: 400px;
    width: 80vw;
    padding: 30px 0 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .TitleWrapper {
    font-size: 24px;
    background: #555555;
    border-radius: 9px 9px 0px 0px;
    height: 40px;
    color: #e5e2e2;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Button {
    color: #e5e2e2;
    border: none;
    background: #8a8a8a;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 3px 3px rgba(211, 206, 206, 0.185);
    }
    &:active {
      background: #706f6f;
    }
  }

  .TableWrapper {
    background-color: #ffffff;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
    height: 600px;
  }
}

@media (max-width: 480px) {
  .Wrapper {
    min-width: 300px;
  }

  .Table th {
    font-size: 18px;
    font-weight: 500;
  }

  .Table td {
    height: 40px;
    text-align: center;
    font-size: 18px;
  }
  .Title {
    font-size: 18px;
  }
}

@media (max-width: 360px) {
  .Wrapper {
    width: 100%;
  }
}
