@media (min-width: 1845px) {
  .Wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 30px 120px 30px 120px;
    position: relative;
    margin: 0 auto;
    max-width: 1900px;
  }

  .Logo {
    width: 100%;
    margin-bottom: 30px;
  }

  .BookingWrapper {
    width: 49%;
    max-width: 750px;
  }

  .ButtonWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 70px;
  }

  .ButtonWrapper button:last-child {
    border-left: 1px solid rgb(34, 34, 34);
  }

  .Button {
    background: none;
    border: none;
    font-size: 20px;
    font-weight: 200;
    cursor: pointer;
    padding: 10px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #555555;
    color: #e5e2e2;
    &_Active {
      background-color: rgb(133, 133, 133);
    }
  }

  .Button:hover {
    background-color: rgb(133, 133, 133);
  }

  .PopoverWrapper {
    background-color: #00000036;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    text-align: center;
    z-index: 10;
  }

  .PopoverTimeOut {
    width: 500px;
    background-color: #eeeeee;
    position: fixed;
    top: calc(40%);
    left: calc(50%);
    margin-left: -250px;
    margin-top: -100px;
    padding: 30px;
    font-size: 22px;
    border-radius: 8px;
  }

  .PopoverTimeOut p {
    margin-bottom: 20px;
  }

  .LogoutButton {
    padding: 5px 10px;
    font-size: 18px;
    border-radius: 8px;
    height: 50px;
    width: 80px;
    border-radius: 16px;
    background-color: #b9b9b9;
    border: 1px solid #cdcdcd;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 3px 3px rgba(99, 99, 99, 0.2);
    }
    &:active {
      box-shadow: 0px 0px 4px 4px rgba(92, 96, 99, 0.2);
      transform: scale(1.1);
      background-color: #838383;
      transition: all 0.5s;
    }
  }
  .SelectSpaceWrapper {
    width: 50%;
  }

  .BookingMapWrapper {
    width: 850px;
  }
}

@media (max-width: 1844px) {
  .Wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px 120px 100px 120px;
    height: 100%;
  }

  .Logo {
    width: 100%;
  }

  .Logo img {
    transform: scale(0.8);
  }

  .ButtonWrapper {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .ButtonWrapper button:last-child {
    border-left: 1px solid rgb(34, 34, 34);
  }

  .Button {
    background: none;
    border: none;
    font-size: 18px;
    font-weight: 200;
    cursor: pointer;
    padding: 10px;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e5e2e2;
    background-color: #555555;
    &_Active {
      background-color: rgb(133, 133, 133);
    }
    &:hover {
      background-color: rgb(133, 133, 133);
    }
  }

  .PopoverWrapper {
    background-color: #00000036;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    text-align: center;
    z-index: 10;
  }

  .PopoverTimeOut {
    width: 500px;
    background-color: #eeeeee;
    position: fixed;
    top: calc(40%);
    left: calc(50%);
    margin-left: -250px;
    margin-top: -100px;
    padding: 30px;
    font-size: 22px;
    border-radius: 8px;
  }

  .PopoverTimeOut p {
    margin-bottom: 20px;
  }

  .LogoutButton {
    padding: 5px 10px;
    font-size: 18px;
    border-radius: 8px;
    height: 50px;
    width: 80px;
    border-radius: 16px;
    background-color: #b9b9b9;
    border: 1px solid #cdcdcd;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 3px 3px rgba(99, 99, 99, 0.2);
    }
    &:active {
      box-shadow: 0px 0px 4px 4px rgba(92, 96, 99, 0.2);
      transform: scale(1.1);
      background-color: #838383;
      transition: all 0.5s;
    }
  }
  .BookingMapWrapper {
    max-width: 750px;
  }
}

@media (max-width: 970px) {
  .Wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 30px 40px 100px 40px;
    position: relative;
    margin: 0 auto;
    max-width: 1900px;
    height: 100%;
  }

  .Logo {
    width: 100%;
  }

  .Logo img {
    transform: scale(0.8);
  }

  .ButtonWrapper {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .ButtonWrapper button:last-child {
    border-left: 1px solid rgb(34, 34, 34);
  }

  .PopoverWrapper {
    background-color: #00000036;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    text-align: center;
    z-index: 10;
  }

  .PopoverTimeOut {
    width: 300px;
    background-color: #eeeeee;
    position: fixed;
    top: calc(40%);
    left: calc(50%);
    margin-left: -150px;
    margin-top: -100px;
    padding: 30px;
    font-size: 22px;
    border-radius: 8px;
  }

  .PopoverTimeOut p {
    margin-bottom: 20px;
  }

  .LogoutButton {
    padding: 5px 10px;
    font-size: 18px;
    border-radius: 8px;
    height: 50px;
    width: 80px;
    border-radius: 16px;
    background-color: #b9b9b9;
    border: 1px solid #cdcdcd;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 3px 3px rgba(99, 99, 99, 0.2);
    }
    &:active {
      box-shadow: 0px 0px 4px 4px rgba(92, 96, 99, 0.2);
      transform: scale(1.1);
      background-color: #838383;
      transition: all 0.5s;
    }
  }
  .DescriptionWrapper {
    margin-bottom: 16px;
    font-size: 18px;
  }
  .DescriptionTitle {
    font-weight: 600;
  }
}

@media (max-width: 480px) {
  .Wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 30px 40px 100px 40px;
    position: relative;
    margin: 0 auto;
    height: 100%;
  }

  .Logo {
    width: 100%;
    display: none;
  }

  .ButtonWrapper {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .ButtonWrapper button:last-child {
    border-left: 1px solid rgb(34, 34, 34);
  }

  .Button {
    background: none;
    border: none;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #555555;
    font-size: 20px;
    font-weight: 200;
    cursor: pointer;
    padding: 10px;
    width: 50%;
    color: #e5e2e2;
    &_Active {
      background-color: rgb(133, 133, 133);
    }
  }

  .PopoverWrapper {
    background-color: #00000036;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    text-align: center;
  }

  .PopoverTimeOut {
    width: 250px;
    background-color: #eeeeee;
    position: fixed;
    top: calc(40%);
    left: calc(50%);
    margin-left: -125px;
    padding: 30px;
    font-size: 22px;
    border-radius: 8px;
  }

  .PopoverTimeOut p {
    margin-bottom: 20px;
  }

  .LogoutButton {
    padding: 5px 10px;
    font-size: 18px;
    border-radius: 8px;
    height: 50px;
    width: 80px;
    border-radius: 16px;
    background-color: #b9b9b9;
    border: 1px solid #cdcdcd;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 3px 3px rgba(99, 99, 99, 0.2);
    }
    &:active {
      box-shadow: 0px 0px 4px 4px rgba(92, 96, 99, 0.2);
      transform: scale(1.1);
      background-color: #838383;
      transition: all 0.5s;
    }
  }
  .DescriptionWrapper {
    margin-bottom: 16px;
  }
  .DescriptionTitle {
    font-weight: 600;
  }
}

@media (max-width: 360px) {
  .Wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 30px 10px 100px 10px;
    position: relative;
    margin: 0 auto;
    height: 100%;
  }
}
